<!--  -->
<template>
    <div class="">
        <div class="title">作品上传</div>
        <upload-opus
            v-if="opusRefreshFlag"
            ref="uploadOpus"
            :tags="opusTags"
            :category="opusCategory"
        ></upload-opus>
        <el-button @click="handleUploadOpus" type="primary">上传</el-button>
    </div>
</template>

<script>
import UploadOpus from '@/views/recruit/components/UploadOpus.vue';
import { getAllTag, getCategoryOpus, postOpus } from '@/api/recruit';

export default {
    name: 'OpusUpload',
    components: {
        UploadOpus,
    },
    data() {
        return {
            opusTags: {}, // 作品标签
            opusCategoryOrigin: {}, // 一级二级分类的后端原数据
            opusRefreshFlag: true, // 刷新标识位
        };
    },
    computed: {
        // 上传作品一级二级分类
        // 将后端数据整理成{id:{label:"",children:{id:{label:""}}}} 的格式
        opusCategory() {
            let category = {};
            Object.keys(this.opusCategoryOrigin).forEach((key) => {
                category[key] = {
                    label: this.opusCategoryOrigin[key].name,
                    children: {},
                };

                this.opusCategoryOrigin[key].sub_category.forEach((subCategory) => {
                    category[key].children[subCategory.id] = { label: subCategory.name };
                });
            });
            return category;
        },
    },
    methods: {
        init() {
            this.getAllTag();
            this.getCategoryOpus();
        },
        // 获取标签
        getAllTag() {
            getAllTag().then(([response, error]) => {
                if (!error) this.opusTags = response.data;
            });
        },
        // 获取一级二级分类
        getCategoryOpus() {
            getCategoryOpus().then(([response, error]) => {
                if (!error) this.opusCategoryOrigin = response.data;
                else {
                    console.warn(response.msg);
                }
            });
        },
        // 处理提交上传作品
        handleUploadOpus() {
            // 同步oss文件列表到form
            let syncStatus = this.$refs.uploadOpus.syncUploadFormData();
            if (!syncStatus) {
                this.$message({
                    type: 'warning',
                    message: '请稍等，文件正在上传中',
                });
                return;
            }
            this.$refs.uploadOpus.$refs.uploadForm.validate((valid) => {
                if (!valid) {
                    return;
                }

                this.postOpus(this.$refs.uploadOpus.uploadForm);
            });
        },
        // 上传作品并更新作品列表
        postOpus(opusForm) {
            postOpus(opusForm).then(([, error]) => {
                if (!error) {
                    this.$message({
                        type: 'success',
                        message: '上传成功',
                    });
                    this.opusRefreshFlag = false;
                    this.$nextTick(() => {
                        this.opusRefreshFlag = true;
                    });
                    this.$router.push('/');
                } else {
                    this.$message({
                        type: 'error',
                        message: error,
                    });
                }
            });
        },
    },
    mounted() {
        this.init();
    },
};
</script>
<style lang="scss" scoped>
.title {
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
}
</style>
